import { createAction, createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

const srpSupplementalDuck = resultsDuckCreator({
    ...srpDuckPreset,
    initialState: {
        activeResults: [],
        count: 0,
        stats: {},
        loading: false,
        initialLoad: true,
        fetchError: false,
        isWebComponentReady: false,
        loadedCount: 0,
    },
    store: 'supplemental',
}).extend({
    types: [
        'SET_LOADED_COUNT',
    ],
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_LOADED_COUNT: {

                return {
                    ...state,
                    loadedCount: action.payload,
                };
            }

            default:
                return state;
        }
    },
    creators: ({ types }) => ({
        setLoadedCount: createAction(types.SET_LOADED_COUNT),
    }),
    selectors: () => ({
        // get count of current lazy loaded vehicles on the page
        getLoadedCount: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (localState) => localState?.loadedCount || 0,
        )),
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
        getResultCount: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (localState) => localState?.count || 0,
        )),
        getLoading: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).loading),
    }),
});

export default srpSupplementalDuck;
